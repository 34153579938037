import {
  ModelMetadata,
  BaseSceneObjects,
  DisplayMode,
} from '@wearitar/model-display-tools';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { FullScreenButton } from './FullScreenButton';
import { PoweredBy } from './PoweredBy';
import fscreen from 'fscreen';
import { DisplayModeSwitcher } from './DisplayModeSwitcher';
import { ActionButton } from './ActionButton';
import { postAnalytics } from '../api/model/postAnalytics';
import { getReferer } from '../utils/getReferer';
import { VariantsDropdown } from './VariantPanel/VariantsDropdown';
import { getTryOnTimeInSeconds } from '../utils/getTryOnTimeInSeconds';
import { CloseButton } from './CloseButton';
import { ModelLoading } from './Loading/ModelLoading';
import { ImageContextProvider } from '../context/ImageProvider';
import { Object3D } from 'three';
import React from 'react';
import { FullModelLoading } from './FullModelLoading';

const SIZE_DENOMINATOR = 1000;
export const DEG2RAD = Math.PI / 180;

async function useBaseSceneObjectFactory(
  metadata: ModelMetadata,
  canvas: HTMLCanvasElement
) {
  const module = await import('@wearitar/model-display-tools');
  return module.baseSceneObjectsFactory.create(metadata, canvas);
}

type Props = {
  metadata: ModelMetadata;
};

const Wearitar3DViewer = React.lazy(
  () => import('./Wearitar3DViewer' /* webpackChunkName: "Wearitar3DViewer" */)
);
const WearitarTryOnEngine = React.lazy(
  () =>
    import(
      './WearitarTryOnEngine' /* webpackChunkName: "WearitarTryOnEngine" */
    )
);

export function WearitarComponentManager({ metadata }: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [baseScene, setBaseScene] = useState<BaseSceneObjects>();
  const [scale, setScale] = useState<number>();
  const handleError = useErrorHandler();
  const [loading, setLoading] = useState(true);
  const [tryOnSessionStartedAt, setTryOnSessionStartedAt] = useState<Date>();
  const [tryOnSessionEndedAt, setTryOnSessionEndedAt] = useState<Date>();
  const [tryOn, setTryOn] = useState(metadata.displayMode === DisplayMode.ar);
  const [isTryonStarted, setIsTryonStarted] = useState(
    metadata.displayMode === DisplayMode.all
  );
  const [activePreset, setActivePreset] = useState(
    !metadata.viewer?.disablePresets ? metadata.viewer?.presets[0] : undefined
  );

  const [activeVariant, setActiveVariant] = useState<string | undefined>(
    metadata.viewer?.defaultVariant !== undefined
      ? metadata.viewer?.defaultVariant
      : metadata.viewer?.presets[0]?.thumbnails &&
        metadata.viewer?.presets[0]?.thumbnails.size === 1
      ? metadata.viewer?.presets[0]?.thumbnails?.keys().next().value
      : undefined
  );

  useEffect(() => {
    // It means there is only one variant. So, we can set it as default for the time being.
    if (
      metadata.viewer &&
      !metadata.viewer?.defaultVariant &&
      metadata.viewer?.presets[0]?.thumbnails &&
      metadata.viewer?.presets[0]?.thumbnails.size === 1 &&
      activeVariant
    ) {
      metadata.viewer.defaultVariant = activeVariant;
    }
  }, [activeVariant]);

  const activeVariantRef = useRef<string | undefined>(activeVariant);

  const { disablePresets } = metadata?.viewer || {};

  const fullScreenHandle = useFullScreenHandle();
  // Due to the bug in android chrome height calculation right after the fullscreen mode is on,
  // the bottom navigation bar should be displayed
  fscreen.requestFullscreen = (element: Element) => {
    const func = fscreen.requestFullscreenFunction(element).bind(element);
    func({
      navigationUI: 'show',
    } as any);
  };

  useEffect(() => {
    (async () => {
      if (typeof baseScene === 'undefined' && canvasRef.current) {
        setScale(Number(metadata.size) / SIZE_DENOMINATOR);

        if (loading) {
          await useBaseSceneObjectFactory(metadata, canvasRef.current).then(
            (base) => {
              const variant = activeVariantRef.current
                ? base.gltfModel.scenes.find(
                    (scene) => scene.name === activeVariantRef.current
                  ) || base.gltfModel.scene
                : base.gltfModel.scene;

              base.scene.children = [];

              setBaseScene({
                ...base,
                activeVariant: variant,
              });
              setLoading(false);
            }
          );
        }

        // Set background color for fullscreen mode via css variable
        fullScreenHandle.node.current?.style.setProperty(
          '--backgroundColor',
          metadata.viewer?.backgroundColor || '#fff'
        );
      }
    })().catch((error) => {
      // If it is the axios network error, show the message from the body
      if (error?.response?.data) {
        return handleError(new Error(error?.response?.data));
      }
      return handleError(error);
    });
  }, [metadata, canvasRef, handleError]);

  useEffect(() => {
    fullScreenHandle.exit();
  }, [tryOn]);

  useEffect(() => {
    if (!tryOnSessionStartedAt) {
      return;
    }
    postAnalytics({
      modelId: metadata.modelId,
      eventName: 'loaded',
      viewType: 'tryon',
      payload: { referer: getReferer() },
    });
    setTryOnSessionStartedAt(tryOnSessionStartedAt);
  }, [tryOnSessionStartedAt]);

  useEffect(() => {
    if (!tryOnSessionStartedAt) {
      return;
    }
    postAnalytics({
      modelId: metadata.modelId,
      eventName: 'sessionEnded',
      viewType: 'tryon',
      payload: {
        tryOnTime: getTryOnTimeInSeconds(tryOnSessionStartedAt),
      },
    });
  }, [tryOnSessionEndedAt]);

  const canvasBackgroundColor =
    !tryOn &&
    !metadata.viewer?.makeTransparent &&
    metadata.viewer?.backgroundColor
      ? metadata.viewer?.backgroundColor
      : undefined;

  const getVariantsFromMetadata = () => {
    if (!metadata.viewer || !metadata.viewer.presets) {
      return [];
    }

    for (const preset of metadata.viewer.presets) {
      if (preset.thumbnails) {
        return Array.from(preset.thumbnails.keys());
      }
    }
    return [];
  };

  return (
    <FullScreen
      handle={fullScreenHandle}
      className="flex justify-center items-center h-[100vh]"
    >
      <canvas
        ref={canvasRef}
        className={
          tryOn ? '' : 'cursor-grab active:cursor-grabbing w-full h-full'
        }
        style={{
          backgroundColor: canvasBackgroundColor,
        }}
      />
      <ImageContextProvider metadata={metadata}>
        {loading && (
          <>
            <ModelLoading
              model={metadata}
              activeVariant={activeVariant}
              activePreset={activePreset}
              setActivePreset={setActivePreset}
            >
              <div className="flex justify-between sm:hidden w-screen mb-2 pr-2">
                <PoweredBy className="pointer-events-auto" />
                {fullScreenHandle && fscreen.fullscreenEnabled && (
                  <FullScreenButton
                    fullScreenHandle={fullScreenHandle}
                    className="pointer-events-auto"
                  />
                )}
              </div>
            </ModelLoading>

            <div
              className={
                'z-10 absolute bottom-2 left-0 right-2 sm:flex justify-between ' +
                (tryOn ||
                metadata.viewer?.presets.length === 0 ||
                disablePresets
                  ? 'flex'
                  : 'hidden')
              }
            >
              <PoweredBy />
              {fullScreenHandle && fscreen.fullscreenEnabled && (
                <FullScreenButton fullScreenHandle={fullScreenHandle} />
              )}
            </div>

            {activeVariant && (
              <VariantsDropdown
                defaultVariant={activeVariant}
                variants={getVariantsFromMetadata()}
                presets={metadata.viewer?.presets || []}
                onSelect={(variant) => {
                  activeVariantRef.current = variant;
                  setActiveVariant(variant);
                }}
              />
            )}
          </>
        )}

        {metadata.displayMode === DisplayMode.all && !loading && (
          <DisplayModeSwitcher
            displayMode={tryOn ? DisplayMode.ar : DisplayMode.viewer}
            onChange={(mode) => setTryOn(mode === DisplayMode.ar)}
          />
        )}

        <div className="flex z-10 absolute gap-2 right-3 top-3 items-end">
          {metadata.actionButton && (
            <ActionButton
              url={metadata.actionButton.url}
              text={metadata.actionButton.text}
              target="_blank"
              onClick={() => {
                const payload: { tryOnTime?: number } = {};
                if (tryOn && tryOnSessionStartedAt) {
                  payload.tryOnTime = getTryOnTimeInSeconds(
                    tryOnSessionStartedAt
                  );
                }
                postAnalytics({
                  modelId: metadata.modelId,
                  eventName: 'actionButtonClicked',
                  viewType: tryOn ? 'tryon' : 'view',
                  payload,
                });
                if (metadata.displayMode === DisplayMode.ar) {
                  setIsTryonStarted(false);
                } else {
                  setTryOn(false);
                }
              }}
            />
          )}

          {isTryonStarted && metadata.displayMode === DisplayMode.ar && (
            <CloseButton
              className="-mr-2"
              onClick={() => setIsTryonStarted(false)}
            />
          )}
        </div>

        {baseScene && typeof scale !== 'undefined' && tryOn && (
          <Suspense
            fallback={
              metadata.displayMode === DisplayMode.ar ? (
                <FullModelLoading
                  metadata={metadata}
                  activeVariant={activeVariant}
                  activePreset={activePreset}
                  setActivePreset={setActivePreset}
                  fullScreenHandle={fullScreenHandle}
                  getVariantsFromMetadata={getVariantsFromMetadata}
                  activeVariantRef={activeVariantRef}
                  setActiveVariant={setActiveVariant}
                />
              ) : (
                <Wearitar3DViewer
                  baseScene={baseScene}
                  presets={metadata.viewer?.presets || []}
                  activePreset={activePreset}
                  disablePresets={disablePresets}
                  onLoaded={() => {
                    postAnalytics({
                      modelId: metadata.modelId,
                      viewType: 'view',
                      eventName: 'loaded',
                      payload: { referer: getReferer() },
                    });
                  }}
                >
                  <div className="flex justify-between sm:hidden w-screen mb-2 pr-2">
                    <PoweredBy className="pointer-events-auto" />
                    {fullScreenHandle && fscreen.fullscreenEnabled && (
                      <FullScreenButton
                        fullScreenHandle={fullScreenHandle}
                        className="pointer-events-auto"
                      />
                    )}
                  </div>
                </Wearitar3DViewer>
              )
            }
          >
            <WearitarTryOnEngine
              baseScene={baseScene}
              metadata={metadata}
              scale={scale}
              isStarted={isTryonStarted}
              onStartClicked={() => setIsTryonStarted(true)}
              onSessionStarted={() => {
                setTryOnSessionStartedAt(new Date());
              }}
              onSessionEnded={() => {
                setTryOnSessionEndedAt(new Date());
                // Viewer doesn't require facing modes, so we can remove the mirring.
                baseScene.renderer.domElement.className = '';
              }}
            />
          </Suspense>
        )}

        {baseScene &&
          typeof scale !== 'undefined' &&
          !tryOn &&
          baseScene.activeVariant && (
            <Suspense
              fallback={
                <FullModelLoading
                  metadata={metadata}
                  activeVariant={activeVariant}
                  activePreset={activePreset}
                  setActivePreset={setActivePreset}
                  fullScreenHandle={fullScreenHandle}
                  getVariantsFromMetadata={getVariantsFromMetadata}
                  activeVariantRef={activeVariantRef}
                  setActiveVariant={setActiveVariant}
                />
              }
            >
              <Wearitar3DViewer
                baseScene={baseScene}
                presets={metadata.viewer?.presets || []}
                activePreset={activePreset}
                disablePresets={disablePresets}
                onLoaded={() => {
                  postAnalytics({
                    modelId: metadata.modelId,
                    viewType: 'view',
                    eventName: 'loaded',
                    payload: { referer: getReferer() },
                  });
                }}
              >
                <div className="flex justify-between sm:hidden w-screen mb-2 pr-2">
                  <PoweredBy className="pointer-events-auto" />
                  {fullScreenHandle && fscreen.fullscreenEnabled && (
                    <FullScreenButton
                      fullScreenHandle={fullScreenHandle}
                      className="pointer-events-auto"
                    />
                  )}
                </div>
              </Wearitar3DViewer>
            </Suspense>
          )}

        <div
          className={
            'z-10 absolute bottom-2 left-0 right-2 sm:flex justify-between ' +
            (tryOn || metadata.viewer?.presets.length === 0 || disablePresets
              ? 'flex'
              : 'hidden')
          }
        >
          <PoweredBy />
          {fullScreenHandle && fscreen.fullscreenEnabled && (
            <FullScreenButton fullScreenHandle={fullScreenHandle} />
          )}
        </div>

        {baseScene && baseScene.gltfModel.scenes.length > 1 && (
          <VariantsDropdown
            defaultVariant={baseScene.gltfModel.scene.name}
            variants={baseScene.gltfModel.scenes.map(
              (scene: Object3D) => scene.name
            )}
            presets={metadata.viewer?.presets || []}
            onSelect={(variant: string) => {
              const newVariant = baseScene.gltfModel.scenes.find(
                (scene) => scene.name === variant
              );
              if (!newVariant) {
                console.error(
                  'Variant with name ' +
                    variant +
                    ' not found for the model: ' +
                    metadata.modelId
                );
                return;
              }
              setBaseScene({
                ...baseScene,
                activeVariant: newVariant,
                // camera: createCamera(renderingObject as Group, undefined, true), // Uncomment this line to enable dynamic camera change on variant change once supported.
              });
              setActiveVariant(variant);
            }}
          />
        )}
      </ImageContextProvider>
    </FullScreen>
  );
}
