import { BiX } from 'react-icons/bi';

type Props = {
  onClick: React.MouseEventHandler<SVGElement>;
  className?: string;
};

export const CloseButton = ({ onClick, className }: Props) => (
  <BiX
    size="32"
    title="Close AR"
    onClick={onClick}
    className={
      'text-sky-900 cursor-pointer opacity-90 hover:opacity-100 ' +
      (className || '')
    }
  />
);
