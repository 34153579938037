import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';
import { Button } from './Button';

type Props = {
  direction: 'left' | 'right';
  onClick: () => void;
  className?: string;
};

export const PresetNavButton = ({ direction, onClick, className }: Props) => (
  <Button
    onClick={onClick}
    className={
      'flex items-center justify-center text-center ' + (className || '')
    }
  >
    {direction === 'left' ? (
      <TbChevronLeft size="24" />
    ) : (
      <TbChevronRight size="24" />
    )}
  </Button>
);
