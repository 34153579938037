import { getUnauthorizedClient as getClient } from '@wearitar/model-display-tools';
import { TryonAnalyticsEventType } from '../../types/analytics/TryonAnalyticsEventType';
import { ViewAnalyticsEventType } from '../../types/analytics/ViewAnalyticsEventType';
import { ViewType } from '../../types/analytics/ViewType';

type AnalyticsType = {
  modelId: string;
  eventName: ViewAnalyticsEventType | TryonAnalyticsEventType;
  viewType: ViewType;
  payload?: Record<string, any>;
};

export const postAnalytics = async (data: AnalyticsType) => {
  // In headers, axios also sends UserAgent
  const response = await (
    await getClient(process.env.REACT_APP_API_ENDPOINT!)
  ).post(`analytics`, data);

  return response.data;
};
