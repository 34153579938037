import { HTMLProps } from 'react';
import { Button } from './Button';

type PresetButtonProps = {
  image: string;
} & HTMLProps<HTMLDivElement>;

export const ButtonWithImage = (props: PresetButtonProps) => (
  <Button {...props}>
    <div
      className="w-full h-full relative bg-cover bg-no-repeat bg-center"
      style={{ backgroundImage: `url(${props.image})` }}
    ></div>
  </Button>
);
