import { HTMLProps } from 'react';

export const Button = (props: HTMLProps<HTMLDivElement>) => (
  <div
    {...props}
    className={
      'flex sm:py-1 sm:px-1 border border-sky-800 rounded cursor-pointer opacity-90 hover:opacity-100 ' +
      (props.className || '')
    }
  >
    {props.children}
  </div>
);
