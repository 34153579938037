type Props = {
  url: string;
  text: string;
  target: string;
  onClick?: () => void;
};
export const ActionButton = ({ url, text, target, onClick }: Props) => (
  <a
    href={url}
    className="bg-sky-900 opacity-90 hover:opacity-100 rounded px-2 py-1 cursor-pointer text-white font-semibold"
    target={target}
    rel="noreferrer"
    onClick={onClick}
  >
    {text}
  </a>
);
