import { FullScreenHandle } from 'react-full-screen';
import { BiFullscreen, BiExitFullscreen } from 'react-icons/bi';

type Props = {
  fullScreenHandle: FullScreenHandle;
  className?: string;
};

export const FullScreenButton = ({ fullScreenHandle, className }: Props) => {
  return (
    <button
      title="Fullscreen"
      className={
        'cursor-pointer opacity-90 hover:opacity-100 ' + (className || '')
      }
      onClick={
        fullScreenHandle.active ? fullScreenHandle.exit : fullScreenHandle.enter
      }
    >
      {fullScreenHandle.active ? (
        <BiExitFullscreen size="32" />
      ) : (
        <BiFullscreen size="32" />
      )}
    </button>
  );
};
