export const PoweredBy = ({ className }: { className?: string }) => (
  <div
    className={
      'powered-by opacity-90 hover:opacity-100 h-8 border-sky-900 border-y border-r rounded-r py-1 px-3 align-top font-normal whitespace-pre text-sky-900 ' +
      (className || '')
    }
  >
    <span className="hidden lg:inline">Powered by </span>
    <a href="https://wearitar.com/" target="_blank" rel="noreferrer">
      Wearitar
    </a>
  </div>
);
