import { useEffect, useRef, useState } from 'react';
import { PresetButton } from './PresetButton';
import { Preset } from '@wearitar/model-display-tools';
import { PresetNavButton } from './NavButton';

type Props = {
  presets: Preset[];
  activeVariantName: string;
  onPresetButtonClick: (preset: Preset) => void;
};

export const PresetPanel = ({
  presets,
  activeVariantName,
  onPresetButtonClick,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showNavButtons, setShowNavButtons] = useState(false);

  useEffect(() => {
    const div = ref.current;
    if (!div) {
      return;
    }
    setShowNavButtons(
      div?.scrollHeight > div?.clientHeight ||
        div?.scrollWidth > div?.clientWidth
    );
  }, [ref, window.innerHeight]);

  const handleScroll = (offset: number) => {
    if (ref.current) {
      ref.current.scrollTo({
        top: ref.current.scrollTop + offset,
        left: ref.current.scrollLeft + offset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div
      className={
        'flex items-center gap-2 max-w-[100vw] sm:max-w-md w-full my-2 px-2 pt-0 ' +
        (showNavButtons ? 'justify-between' : 'justify-center')
      }
    >
      {showNavButtons && (
        <PresetNavButton
          className="w-8 h-12 sm:h-14 md:h-16"
          direction="left"
          onClick={() => handleScroll(-100)}
        />
      )}
      <div className="overflow-scroll no-scrollbar" ref={ref}>
        <div className="flex gap-2 scroll-smooth w-max justify-start items-start">
          {presets.map((preset, index) => (
            <PresetButton
              key={index}
              activeVariantName={activeVariantName}
              onClick={(preset) => onPresetButtonClick(preset)}
              preset={preset}
              className="snap-start w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 relative grayscale-[25%] hover:grayscale-0"
            />
          ))}
        </div>
      </div>

      {showNavButtons && (
        <PresetNavButton
          className="w-8 h-12 sm:h-14 md:h-16"
          direction="right"
          onClick={() => handleScroll(100)}
        />
      )}
    </div>
  );
};
