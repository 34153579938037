import { PropsWithChildren, ReactNode, useState } from 'react';
import { DrawerButton } from './PresetPanel/DrawerButton';

export const Drawer = ({
  header,
  children,
  drawerOpenClassName,
  drawerClosedClassName,
}: {
  header?: ReactNode;
  drawerOpenClassName: string;
  drawerClosedClassName: string;
} & PropsWithChildren) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  return (
    <div
      className={
        'absolute z-20 transition-all duration-300 max-w-full flex flex-col justify-center items-start inset-x-0 sm:inset-x-auto sm:gap-4 overflow-hidden pointer-events-none ' +
        (isDrawerOpen ? drawerOpenClassName : drawerClosedClassName)
      }
    >
      {header}
      <div className="flex flex-col bg-gray-50 bg-opacity-50 sm:rounded w-full pointer-events-auto">
        <div
          className="flex cursor-pointer justify-center items-center w-full sm:hidden py-1 hover:bg-gray-100 bg-opacity-70 hover:bg-opacity-70"
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        >
          <DrawerButton />
        </div>

        {children}
      </div>
    </div>
  );
};
