import { HTMLProps, ReactNode, useState } from 'react';

export const Dropdown = (
  props: HTMLProps<HTMLDetailsElement> & { button?: ReactNode }
) => {
  const [isOpen, setIsOpen] = useState(false);
  const iterableChildren = Array.isArray(props.children)
    ? props.children
    : [props.children];

  const { button, ...detailsProps } = props;
  return (
    <details
      {...detailsProps}
      open={isOpen}
      onClick={(e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
      }}
    >
      <summary>{button || iterableChildren[0]}</summary>
      <ul className="overflow-y-scroll h-full">
        {iterableChildren.map((child, i) => (
          <li
            key={i}
            className="mt-2"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
          >
            {child}
          </li>
        ))}
      </ul>
    </details>
  );
};
