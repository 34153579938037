import { ButtonWithImage } from '../PresetPanel/ButtonWithImage';
import { Tooltip } from 'react-tooltip';
import { Preset } from '@wearitar/model-display-tools';
import { Dropdown } from '../Dropdown';
import { useState } from 'react';
import { useImages } from '../../context/ImageProvider';

type Props = {
  defaultVariant: string | undefined;
  variants: string[];
  presets: Preset[];
  onSelect: (variant: string) => void;
};

export const VariantsDropdown = ({
  defaultVariant,
  variants,
  presets,
  onSelect,
}: Props) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const [selectedVariant, setSelectedVariant] = useState(defaultVariant); // Default scene name is the selected variant by default

  const { images, isFullyPreloaded, shouldPreload } = useImages();

  if (
    !selectedVariant ||
    (!isFullyPreloaded && shouldPreload) ||
    !presets[0]?.thumbnails ||
    presets[0].thumbnails.size === 0
  ) {
    return <></>;
  }

  const selectedPresetThumbnail = images[
    presets[0].thumbnails.get(selectedVariant)!
  ]
    ? images[presets[0].thumbnails.get(selectedVariant)!]
    : presets[0].thumbnails!.get(selectedVariant)!;

  // The model has the variants, but there are no presets or there is only one variant.
  if (!selectedPresetThumbnail || variants.length < 2) {
    return <></>;
  }

  return (
    <>
      <Dropdown
        className="absolute z-30 top-3 left-3 h-2/3"
        title={'Selected: ' + selectedVariant.replace('_', ' ')}
        button={<VariantButton image={selectedPresetThumbnail} />}
      >
        {variants.map((scene) => (
          <VariantButton
            key={scene}
            title={scene}
            image={
              images[presets[0].thumbnails.get(scene)!]
                ? images[presets[0].thumbnails!.get(scene)!]
                : presets[0].thumbnails.get(scene)!
            }
            onClick={() => {
              setSelectedVariant(scene);
              onSelect(scene);
              setTooltipIsOpen(false);
            }}
            onMouseEnter={() => setTooltipIsOpen(true)}
            onMouseLeave={() => setTooltipIsOpen(false)}
            data-tooltip-id="variants-tooltip"
            data-tooltip-content={scene.replace('_', ' ')}
            data-tooltip-place="right"
          />
        ))}
      </Dropdown>
      <Tooltip
        id="variants-tooltip"
        className="absolute z-50"
        isOpen={tooltipIsOpen}
      />
    </>
  );
};

const VariantButton = (props: Parameters<typeof ButtonWithImage>[0]) => (
  <ButtonWithImage
    {...props}
    className="flex sm:py-1 sm:px-1 border border-sky-800 rounded cursor-pointer opacity-90 hover:opacity-100 snap-start w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 relative bg-white"
  />
);
