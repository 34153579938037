import { HTMLAttributes } from 'react';
import { TbLoader2 } from 'react-icons/tb';

export const Loading = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={
      'absolute flex flex-col justify-center items-center top-0 bottom-0 left-0 right-0 w-full h-full text-sky-900 ' +
      className
    }
    {...props}
  >
    <TbLoader2 className="w-24 h-24 mr-2 text-gray-200 animate-spin dark:text-gray-600 stroke-sky-900" />
    {children}
  </div>
);
