import { Compatibility } from './components/Compatibility';
import { WearitarComponentManager } from './components/WearitarComponentManager';
import { useMetadata } from './hooks/useMetadata';
import { Background } from './components/Background';
import { DisplayMode, isWebGL2Supported } from '@wearitar/model-display-tools';
import { useInlineScript } from './hooks/useInlineScript';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import './aws-rum-web';

function App() {
  useInlineScript('//js.hs-scripts.com/40131026.js', {
    id: 'hs-script-loader',
  });
  const params = new URL(document.location.href).searchParams;
  const modelId = params.get('modelId');
  const metadata = useMetadata(modelId || '');

  if (
    !window.navigator.mediaDevices ||
    !window.navigator.mediaDevices.getUserMedia ||
    !isWebGL2Supported()
  ) {
    return <Compatibility />;
  }

  if (!metadata.modelPath) {
    return <></>;
  }

  const isPageInIframe = window.location !== window.parent.location;

  return (
    <HelmetProvider>
      <Helmet>
        {!isPageInIframe && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>

      {metadata.displayMode === DisplayMode.ar && (
        <>
          <div
            className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center w-full h-full bg-no-repeat bg-center bg-contain bg-white"
            style={{
              backgroundImage: 'url(/assets/watch-model-bg.webp)',
            }}
          ></div>
          {metadata.ar?.background && (
            <Background
              watchStrokeColor={metadata.ar.background.watchStrokeColor}
              watchFillColor={metadata.ar.background.watchFillColor}
              backgroundColor={metadata.ar.background.backgroundColor}
            />
          )}
        </>
      )}
      <WearitarComponentManager metadata={metadata} />
    </HelmetProvider>
  );
}

export default App;
