import { ModelMetadata, Preset } from '@wearitar/model-display-tools';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

type PreloadedImages = Record<string, string>;

type LoadCount = {
  totalImagesToPreload: number;
  defaultPresetsCount: number;
  loadedDefault: number;
  loadedCount: number;
};

export const usePreloadThumbnails = (
  metadata: ModelMetadata
): {
  images: PreloadedImages;
  isDefaultPreloaded: boolean;
  isFullyPreloaded: boolean;
  shouldPreload: boolean;
} => {
  const [images, setImages] = useState<PreloadedImages>({});
  const [isDefaultPreloaded, setIsDefaultPreloaded] = useState(false);
  const [isFullyPreloaded, setIsFullyPreloaded] = useState(false);
  const [shouldPreload, setShouldPreload] = useState<boolean>(
    metadata.viewer !== undefined &&
      !metadata.viewer?.disablePresets &&
      metadata.viewer.defaultVariant !== undefined &&
      metadata.viewer.presets &&
      metadata.viewer.presets.length > 0
  );

  const countsRef = useRef<LoadCount>({
    totalImagesToPreload: 0,
    defaultPresetsCount: 0,
    loadedDefault: 0,
    loadedCount: 0,
  });

  // Effect to preload default variant images
  useEffect(() => {
    const preloadDefaultVariants = () => {
      if (metadata.viewer && metadata.viewer.defaultVariant !== undefined) {
        const defaultVariant = metadata.viewer.defaultVariant;
        const presets = metadata.viewer.presets || [];

        // Reset counts
        countsRef.current = {
          totalImagesToPreload: 0,
          defaultPresetsCount: 0,
          loadedDefault: 0,
          loadedCount: 0,
        };

        // Calculate counts for default variants
        presets.forEach((preset: Preset) => {
          if (preset.thumbnails) {
            if (
              preset.thumbnails.has(defaultVariant) &&
              preset.thumbnails.get(defaultVariant)?.startsWith('http')
            ) {
              countsRef.current.totalImagesToPreload += 1;
              countsRef.current.defaultPresetsCount += 1;
            }
          }
        });

        if (
          countsRef.current.defaultPresetsCount === 0 ||
          countsRef.current.totalImagesToPreload === 0
        ) {
          checkIfPreloadingComplete(true);
          return;
        }
        // Preload images for the default variant
        presets.forEach((preset: Preset) => {
          if (preset.thumbnails) {
            preloadImageForVariant(
              preset.thumbnails,
              defaultVariant,
              true // isDefaultPreset
            );
          }
        });
      } else {
        setShouldPreload(false);
      }
    };

    preloadDefaultVariants();
  }, [metadata.viewer?.presets, metadata.viewer?.defaultVariant]);

  // Effect to preload other variants when default is preloaded
  useEffect(() => {
    if (isDefaultPreloaded) {
      const preloadOtherVariants = () => {
        if (metadata.viewer && metadata.viewer.defaultVariant !== undefined) {
          const defaultVariant = metadata.viewer.defaultVariant;
          const presets = metadata.viewer.presets || [];

          presets.forEach((preset: Preset) => {
            preset.thumbnails?.forEach((thumbnail, variant) => {
              if (variant !== defaultVariant && thumbnail.startsWith('http')) {
                countsRef.current.totalImagesToPreload += 1;
              }
            });
          });

          if (
            countsRef.current.totalImagesToPreload ===
            countsRef.current.defaultPresetsCount
          ) {
            checkIfPreloadingComplete(false);
            return;
          }

          // Preload images for other variants
          presets.forEach((preset: Preset) => {
            if (preset.thumbnails) {
              for (const variant of preset.thumbnails.keys()) {
                if (variant !== defaultVariant) {
                  preloadImageForVariant(
                    preset.thumbnails,
                    variant,
                    false // isDefaultPreset
                  );
                }
              }
            }
          });
        }
      };

      preloadOtherVariants();
    }
  }, [
    isDefaultPreloaded,
    metadata.viewer?.presets,
    metadata.viewer?.defaultVariant,
  ]);

  const checkIfPreloadingComplete = (isDefaultVariant: boolean) => {
    const counts = countsRef.current;

    // If it is a default variant, we don't know if there are other variants to preload yet, so we need to check first.
    if (counts.totalImagesToPreload === 0 && !isDefaultVariant) {
      setShouldPreload(false);
      setIsFullyPreloaded(false);
      setIsDefaultPreloaded(false);
      return;
    }

    if (
      isDefaultVariant &&
      counts.loadedDefault === counts.defaultPresetsCount &&
      !isDefaultPreloaded
    ) {
      setIsDefaultPreloaded(true);
    }

    if (
      !isDefaultVariant &&
      counts.loadedCount === counts.totalImagesToPreload &&
      !isFullyPreloaded
    ) {
      setIsFullyPreloaded(true);
    }
  };

  const preloadImageForVariant = (
    thumbnails: Map<string, string>,
    variant: string,
    isDefaultPreset: boolean
  ) => {
    const imageUrl = thumbnails.get(variant);

    const counts = countsRef.current;

    if (!imageUrl || imageUrl.startsWith('data:image')) {
      // We accounted for it when calculated totals. Just skip
      return;
    }

    axios
      .get(imageUrl, { responseType: 'blob', withCredentials: false })
      .then((response) => {
        const originalBlob = response.data;
        // Check if the blob type is 'image/webp'
        if (originalBlob.type === 'application/octet-stream') {
          // If not, create a new Blob with the correct MIME type
          const webpBlob = originalBlob.slice(
            0,
            originalBlob.size,
            'image/webp'
          );
          return webpBlob;
        }

        return originalBlob;
      })
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              if (typeof reader.result === 'string') {
                resolve(reader.result);
              } else {
                reject(new Error('Failed to convert blob to data URL.'));
              }
            };
            reader.onerror = () => {
              reject(new Error('Error reading blob.'));
            };
            reader.readAsDataURL(blob);
          })
      )
      .then((dataURL) => {
        setImages((prevImages) => ({
          ...prevImages,
          [imageUrl]: dataURL as string,
        }));

        if (isDefaultPreset) {
          counts.loadedDefault += 1;
          counts.loadedCount += 1;
          checkIfPreloadingComplete(true);
        } else {
          counts.loadedCount += 1;
          checkIfPreloadingComplete(false);
        }
      })
      .catch((e) => {
        console.error(`Failed to load image: ${imageUrl}. Error: ${e.message}`);

        // Update counts even if there's an error
        if (isDefaultPreset) {
          counts.defaultPresetsCount -= 1;
          counts.totalImagesToPreload -= 1;
          checkIfPreloadingComplete(true);
        } else {
          counts.totalImagesToPreload -= 1;
          checkIfPreloadingComplete(false);
        }
      });
  };

  return { images, isDefaultPreloaded, isFullyPreloaded, shouldPreload };
};
