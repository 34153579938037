import { Tb360View } from 'react-icons/tb';
import { Preset } from '@wearitar/model-display-tools';
import { Button } from './Button';
import { useImages } from '../../context/ImageProvider';

type PresetButtonProps = {
  preset: Preset;
  activeVariantName: string;
  onClick: (preset: Preset) => void;
  className?: string;
};

export const PresetButton = ({
  preset,
  activeVariantName,
  onClick,
  className,
}: PresetButtonProps) => {
  const { images, isDefaultPreloaded, shouldPreload } = useImages();

  return (
    // We should only render, when the URLs are S3 images and those are preloaded or if URLs are not S3 images, i.e. new model
    ((!preset.thumbnails?.get(activeVariantName)?.startsWith('data:image') &&
      (isDefaultPreloaded || !shouldPreload)) ||
      preset.thumbnails.get(activeVariantName)?.startsWith('data:image')) && (
      <Button
        onClick={() => onClick(preset)}
        className={className}
        title={`Preset ${preset.id}`}
      >
        <div
          className="w-full h-full relative bg-cover bg-no-repeat bg-center"
          style={{
            backgroundImage: `url(${
              preset.thumbnails?.get(activeVariantName) &&
              images[preset.thumbnails.get(activeVariantName)!]
                ? images[preset.thumbnails.get(activeVariantName)!]
                : preset.thumbnails?.get(activeVariantName)
            })`,
          }}
        >
          {preset.rotate && (
            <Tb360View
              className="absolute top-1 right-1 sm:top-0 sm:right-0"
              size="35%"
            />
          )}
        </div>
      </Button>
    )
  );
};
