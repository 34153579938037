import { ModelMetadata, Preset } from '@wearitar/model-display-tools';
import React, { HTMLProps } from 'react';
import { ModelLoading } from './Loading/ModelLoading';
import { PoweredBy } from './PoweredBy';
import fscreen from 'fscreen';
import { FullScreenHandle } from 'react-full-screen';
import { FullScreenButton } from './FullScreenButton';
import { VariantsDropdown } from './VariantPanel/VariantsDropdown';

type Props = {
  metadata: ModelMetadata;
  activeVariant: string | undefined;
  activePreset: Preset | undefined;
  setActivePreset: (preset: Preset) => void;
  fullScreenHandle: FullScreenHandle;
  getVariantsFromMetadata: () => string[];
  activeVariantRef: React.MutableRefObject<string | undefined>;
  setActiveVariant: (variant: string) => void;
} & HTMLProps<HTMLDivElement>;

export const FullModelLoading = ({
  metadata,
  activeVariant,
  activePreset,
  setActivePreset,
  fullScreenHandle,
  getVariantsFromMetadata,
  activeVariantRef,
  setActiveVariant,
}: Props) => (
  <>
    <ModelLoading
      model={metadata}
      activeVariant={activeVariant}
      activePreset={activePreset}
      setActivePreset={setActivePreset}
    >
      <div className="flex justify-between sm:hidden w-screen mb-2 pr-2">
        <PoweredBy className="pointer-events-auto" />
        {fullScreenHandle && fscreen.fullscreenEnabled && (
          <FullScreenButton
            fullScreenHandle={fullScreenHandle}
            className="pointer-events-auto"
          />
        )}
      </div>
    </ModelLoading>

    {/* TEST. Trying without it */}
    {/* 
              <div
                className={
                  'z-10 absolute bottom-2 left-0 right-2 sm:flex justify-between ' +
                  (tryOn ||
                  metadata.viewer?.presets.length === 0 ||
                  disablePresets
                    ? 'flex'
                    : 'hidden')
                }
              >
                <PoweredBy />
                {fullScreenHandle && fscreen.fullscreenEnabled && (
                  <FullScreenButton fullScreenHandle={fullScreenHandle} />
                )}
              </div> */}

    {activeVariant && (
      <VariantsDropdown
        defaultVariant={activeVariant}
        variants={getVariantsFromMetadata()}
        presets={metadata.viewer?.presets || []}
        onSelect={(variant) => {
          activeVariantRef.current = variant;
          setActiveVariant(variant);
        }}
      />
    )}
  </>
);
