import { DisplayMode } from '@wearitar/model-display-tools';

type Props = {
  displayMode: DisplayMode;
  onChange: (mode: DisplayMode) => void;
};

const modes = {
  '360': DisplayMode.viewer,
  AR: DisplayMode.ar,
};

export const DisplayModeSwitcher = ({ displayMode, onChange }: Props) => (
  <div className="flex gap-2 z-10 absolute top-3 cursor-pointer text-sky-900 font-bold text-xl rounded p-2 bg-gray-50 bg-opacity-50">
    <div className="border-b border-sky-900">
      {Object.entries(modes).map(([value, mode]) => (
        <button
          key={value}
          title={`Switch to ${value} mode`}
          className={
            'transition ease-in duration-300 w-16 ' +
            (mode === displayMode
              ? 'border-sky-900 border-b-4'
              : 'border-transparent border-b-4')
          }
          onClick={() => onChange(mode as DisplayMode)}
        >
          {value}
        </button>
      ))}
    </div>
  </div>
);
