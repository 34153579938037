export const Compatibility = () => (
  <div className="absolute flex justify-center items-center bg-transparent z-20 top-0 bottom-0 left-0 right-0">
    <div className="bg-black text-white opacity-80 text-center min-h-[50vh] m-5 p-5">
      <h1>Failed to launch :(</h1>
      <p>Looks like your device/browser is not compatible.</p>

      <br />
      <br />
      <p>Please try the following recommended browsers:</p>
      <p>For Android device - Chrome</p>
      <p>For iOS device - Safari</p>
    </div>
  </div>
);
