import '../../css/progress-loader.css';
import { ModelMetadata, Preset } from '@wearitar/model-display-tools';
import { Loading } from './Loading';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Drawer } from '../Drawer';
import { PresetPanel } from '../PresetPanel/PresetPanel';
import { useImages } from '../../context/ImageProvider';

type Props = {
  model: ModelMetadata;
  activeVariant?: string;
  activePreset?: Preset;
  setActivePreset: (preset: Preset) => void;
};

export function ModelLoading({
  model,
  activeVariant,
  activePreset,
  setActivePreset,
  children,
}: Props & PropsWithChildren) {
  const getThumbnailById = (id: string | undefined): string | null => {
    if (
      id === undefined ||
      activeVariant === undefined ||
      model.viewer === undefined ||
      model.viewer?.presets.length < 1
    ) {
      return null;
    }

    for (const preset of model.viewer?.presets || []) {
      if ((preset as Preset).id === id) {
        const thumbnail = preset.thumbnails?.get(activeVariant);
        return thumbnail || null;
      }
    }

    return null;
  };

  const { images, isDefaultPreloaded, isFullyPreloaded } = useImages();
  const [thumbnail, setThumbnail] = useState<string | null>(null);

  useEffect(() => {
    if (isDefaultPreloaded) {
      setThumbnail(getThumbnailById(activePreset?.id));
    }
  }, [
    isDefaultPreloaded,
    isFullyPreloaded,
    images,
    activePreset,
    activeVariant,
  ]);

  const shouldDisplayPresetPanel =
    model.viewer &&
    activeVariant &&
    model.viewer?.presets.length > 0 &&
    !model.viewer?.disablePresets &&
    model.viewer?.presets[0].thumbnails &&
    model.viewer?.presets[0].thumbnails.size > 0 &&
    ((isDefaultPreloaded && activeVariant === model.viewer?.defaultVariant) ||
      (isFullyPreloaded && activeVariant !== model.viewer?.defaultVariant));

  return (
    <div className="absolute z-10 flex items-center justify-center w-full h-full">
      {/* Show loading spinner only when we are not showing images */}
      {!shouldDisplayPresetPanel && <Loading className="-z-[1]" />}
      <div
        className="md:hidden w-full h-full relative bg-cover bg-no-repeat bg-center "
        style={{
          backgroundImage: `url(${
            thumbnail && images[thumbnail]
              ? images[thumbnail]
              : model.thumbnailUrl
          })`,
        }}
      ></div>
      <img
        className="hidden md:block max-w-full max-h-full"
        alt={model.name}
        src={
          thumbnail && images[thumbnail]
            ? images[thumbnail]
            : model.thumbnailUrl!
        }
      />

      {shouldDisplayPresetPanel && model.viewer?.presets && (
        <Drawer
          drawerOpenClassName="bottom-0 sm:bottom-2"
          drawerClosedClassName="-bottom-16"
          header={children}
        >
          <div className="flex justify-start items-center h-16 sm:h-18 md:h-20  box-content w-full">
            <PresetPanel
              presets={model.viewer?.presets}
              activeVariantName={activeVariant!}
              onPresetButtonClick={(preset) => {
                setActivePreset(preset);
              }}
            />
          </div>
        </Drawer>
      )}

      <div className="progress-loader absolute bottom-0 w-full" />
    </div>
  );
}
